import React, { useState, useRef, useEffect } from 'react';
import { Calendar } from 'primereact/calendar';
import { useSelector } from 'react-redux';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Checkbox } from 'primereact/checkbox';
        

import { Tag } from 'primereact/tag';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Paginator } from 'primereact/paginator';
import { classNames } from 'primereact/utils';
import { Link } from 'react-router-dom';

//import Dropdown from '../../../../components/dropdownValue'; // 커스텀 접근성 드롭다운 
import Layout from '../../../../components/lms/layout/layout';
import PDFViewer from '../../../../components/lms/modal/PDFViewer'; 

import axios from 'axios';

import config from '../../../../config';
const apiUrl = config.apiUrl;

var dataList = [];

// 타이틀
export function useTitle(initialTitle) {
    const [title, setTitle] = useState(initialTitle);
    const updateTitle = () => {
        const htmlTitle = document.querySelector("title");
        htmlTitle.innerText = title;
    };

    useEffect(updateTitle, [title]);
    return setTitle;
};


const ClassScheduleDetail = () => {

    useTitle("수업 내역 확인");

    const accessToken = useSelector(state => state.auth.accessToken);

    const [totalCnt, setTotalCnt] = useState(0);
    const activePage = useRef(1);
    const [row, setRowData] = useState(5);
    const [tableData, setTableData] = useState([...dataList]);
    const [formData, setFormData] = useState({
        startYear: '',
        startMonth: '',
        startDay: '',
        endYear: '',
        endMonth: '',
        endDay: '',
        searchtext: ''
    });

    // 날짜 정렬
    const datinglist = [
        { label: '최신순', value: 'desc' },
        { label: '과거순', value: 'asc' }
    ];
    const [sortType, setSortType] = useState('desc');

    //브러드크럼
    const items = [{ label: '수업관리' }, { label: '수업 내역 확인', className: 'nowpage' }, ];
    const home = { icon: 'pi pi-home', url: '/teacher/dashboard' }


    // 조회 기간
    const periodtypelist = [
        { label: '모든 기간', value: '' },
        { label: '1개월', value: '1month' },
        { label: '3개월', value: '3months' },
        { label: '직접설정', value: 'etc' }
    ];
    const [periodType, setPeriodType] = useState('');
    
    // 직접설정 - 날짜 선택
    const [selfDate, setSelfDate] = useState(null);

    // 시작일 - 연도 / 월 / 일
    const [startdatelist, setStartdatelist] = useState([]);
    const [startdatemonthlist, setStartdatemonthlist] = useState(['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']);
    const [startdatedaylist, setStartdatedaylist] = useState([]);
    // 종료일 - 연도 / 월 / 일
    const [enddatelist, setEnddatelist] = useState([]);
    const [enddatemonthlist, setEnddatemonthlist] = useState(['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']);
    const [enddatedaylist, setEnddatedaylist] = useState([]);

    // 날짜 select 값 변경시
    const handleDropdownChange = (event) => {

        const { name, value } = event.target;

        if(name == "startMonth" || name == "endMonth"){
            var updatedDayList;
            if(value == 2){
                updatedDayList = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28'];
            }else if(value == 1, 3, 5, 7, 8, 10, 12){
                updatedDayList = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'];
            }else{
                updatedDayList = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30'];
            }
            if(name == "startMonth"){
                setStartdatedaylist(updatedDayList);
                setFormData({
                    ...formData,
                    [name]: value,
                    //startDay: "1"
                });
            }else{
                setEnddatedaylist(updatedDayList);
                setFormData({
                    ...formData,
                    [name]: value,
                    //endDay: "1"
                  });
            }

            }else{
            setFormData({
                ...formData,
                [name]: value
            });
        }
    };

    // 선택 가능한 연도 (종료일)
    const isEndYearDisabled = (year, month, day, startYear, startMonth, startDay) => {

        if (year === '' || month === '' || day === '') {
            return true; // 연도, 월, 일 중 하나라도 비어 있으면 비활성화
        }

        const selectedStartDate = new Date(startYear, startMonth - 1, startDay);
        const selectedEndDate = new Date(year, month - 1, day);

           // 시작일보다 이전일 경우 비활성화
            return selectedEndDate < selectedStartDate;
    };

    // 선택 가능한 월 (종료일)
    const isEndMonthDisabled = (month,startYear, startMonth, startDay, endYear) => {
        const selectedStartDate = new Date(startYear, startMonth - 1, startDay);
        const selectedEndDate = new Date(endYear, month, 1); 

        if (selectedEndDate < selectedStartDate) {
            return true;
        }

        return false;   
    };

    // 선택 가능한 일 (종료일)
    const isEndDayDisabled = (day, startYear, startMonth, startDay, endYear, endMonth) => {
        const selectedStartDate = new Date(startYear, startMonth - 1, startDay);
        const selectedEndDate = new Date(endYear, endMonth - 1, day - 1);

        if (selectedEndDate < selectedStartDate) {
            return true;
        }

        return false;
   
    };
   
    const [classStatus, setClassStatus] = useState([]);
    const [startdate, setStartdate] = useState(null);
    const [enddate, setEnddate] = useState(null);

  
    const displayText2 = `총 ${totalCnt}건 ${activePage.current}페이지 ${sortType === 'desc' ? '최신순' : '과거순'}으로 표시중`;

    // 수업 진행 개수
    const [joinCount, setJoinCount] = useState('');

    const displayText1 = `진행 ${joinCount}회 / 미진행 ${totalCnt - joinCount}회`;

    // 정렬 기준 변경 시마다 일정 정보 가져오기
    useEffect(() => {

         // 시작일/종료일 연도 셋팅
        var today = new Date();
        var thisYear = today.getFullYear();
        var nextYear = thisYear + 1;
        var yearArr = [thisYear, nextYear];
        setStartdatelist(yearArr);
        setEnddatelist(yearArr);

        getClassSchedule();

    }, [sortType, classStatus]);

    // 조회 기간 변경 시
    useEffect(() => {

        if(periodType == "1month" || periodType == "3months"){
            var edate = new Date();
            var enddateToString = getDateToString(edate);
            setEnddate(enddateToString);

            if(periodType == "1month"){            
                var sdate = edate.setMonth(edate.getMonth() - 1);
                sdate = new Date(sdate);
                var startdateToString = getDateToString(sdate);
                setStartdate(startdateToString);
            }else if(periodType == "3months"){
                var sdate = edate.setMonth(edate.getMonth() - 3);
                sdate = new Date(sdate);
                var startdateToString = getDateToString(sdate);
                setStartdate(startdateToString);
            }
        }else{
            if(periodType == "etc"){
                setFormData({
                    ...formData,
                    startYear: '',
                    startMonth: '',
                    startDay: '',
                    endYear: '',
                    endMonth: '',
                    endDay: ''
                });
            }
            setEnddate(null);
            setStartdate(null);
        }

    }, [periodType]);

    // 조회 - 종료일 변경 시 수업 리스트 가져오기
    useEffect(() => {

        if(startdate && enddate){
            getClassSchedule();
        }

    }, [startdate]);

    // 수업 리스트 가져오기
    const getClassSchedule = async () => {

        var sortStr = "";
        if(sortType !== ""){
            var sorting = "desc";
            if(sortType == "asc"){
                sorting = "asc";
            }
            sortStr = "startdate" + "," + sorting;
        }

        var start = "";
        var end = "";
        var today = new Date();

        if(periodType == "1month" || periodType == "3months"){
            if(startdate && enddate){
                start = startdate;
                end = enddate;
            }else{
                end = getDateToString(today);
                start = "2024-01-01";
            }
        }else if(periodType == "etc"){
            if(formData.startYear && formData.startMonth && formData.startDay){
                start = formData.startYear + "-" + formData.startMonth.toString().padStart(2, '0') + "-" + formData.startDay.toString().padStart(2, '0');
            }else{
                return false;
            }
            if(formData.endYear && formData.endMonth && formData.endDay){
                end = formData.endYear + "-" + formData.endMonth.toString().padStart(2, '0') + "-" + formData.endDay.toString().padStart(2, '0');
            }else{
                return false;
            }

            setStartdate(start);
            setEnddate(end);
        }else{
            end = getDateToString(today);
            start = "2024-01-01";
        }
        
        try {
            const response = await axios.get(apiUrl+`/tutoring/MyClassSchedule?page=${activePage.current}&rows=${row}&sort=${sortStr}&searchtext=${formData.searchtext}&status=${classStatus}&start=${start}&end=${end}`, {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            //console.log(res);
            var dataArr = [];
            if(res.success){
                var resData = response.data.data.schdules;
                //console.log(resData);
                if(resData){
                    setJoinCount(response.data.data.join_count);

                    for(var i = 0; i < resData.length; i++){
                        var info = resData[i];
                        var starttxt = info.starttxt;
                        var starttxtArr = starttxt.split(":");
                        var startampm = "오전";
                        var starthour = Number(starttxtArr[0]);
                        if(starthour > 12){
                            starthour = starthour - 12;
                            startampm = "오후";
                        }
                        starthour = starthour.toString().padStart(2, '0');
                        info.starttxtStr = startampm + " " + starthour + ":" + starttxtArr[1];
                        
                        var endtxt = info.endtxt;
                        var endtxtArr = endtxt.split(":");
                        var endampm = "오전";
                        var endhour = Number(endtxtArr[0]);
                        if(endhour > 12){
                            endhour = endhour - 12;
                            endampm = "오후";
                        }
                        endhour = endhour.toString().padStart(2, '0');
                        info.endtxtStr = endampm + " " + endhour + ":" + endtxtArr[1];

                        info.title = info.starttxtStr;
                        info.id = info.scheduleid;
                        info.start = info.datefulltxt;

                        info.classStatus = "";
                        
                        if(info.status == "대기" || info.status == "승인"){
                            info.classStatus = "미진행";
                        }else if(info.status == "진행" || info.status == "종료"){
                            info.classStatus = "진행";
                        }
                        
                        dataArr.push(info);
                    }
                }

                setTotalCnt(res.total);
            }else{
                setTotalCnt(0);
            }

            dataList = dataArr;

            setTableData(dataList);

        } catch (error) {
            console.error('Error:', error);
        }
    };
    
    // 날짜 직접 설정 시
    const onSelfDateChange = (event) => {
        setSelfDate(event.value);
        if(event.value[1]){
            var sdate = new Date(event.value[0]);
            var startdateToString = getDateToString(sdate);
            setStartdate(startdateToString);
            var edate = new Date(event.value[1]);
            var enddateToString = getDateToString(edate);
            setEnddate(enddateToString);
        }
    };

    // DateToString
    const getDateToString = (targetDate) => {
        let year = targetDate.getFullYear();
        let month = targetDate.getMonth() + 1;
        let day = targetDate.getDate();
    
        // 한 자리 수인 경우 앞에 0을 붙여 두 자리로 만듭니다.
        month = month < 10 ? '0' + month : month;
        day = day < 10 ? '0' + day : day;
    
        const dateToString = year + '-' + month + '-' + day;
        return dateToString;
    };

    // 이전 달의 첫째 날 구하기
    const getFirstDayOfLastMonth = () => {
        const today = new Date(); // 현재 날짜
        const year = today.getFullYear();
        let month = today.getMonth() + 1; // 현재 월
        if (month === 0) {
            // 현재 월이 1월인 경우, 지난 해의 12월로 설정합니다.
            year -= 1;
            month = 12;
        }
        month = month < 10 ? '0' + month : month;

        return year + "-" + month + "-01";
    };

    // 이전 달의 마지막 날 구하기
    const getLastDayOfLastMonth = () => {
        const today = new Date(); // 현재 날짜
        const year = today.getFullYear();
        let month = today.getMonth() + 1; // 현재 월
        if (month === 0) {
            // 현재 월이 1월인 경우, 지난 해의 12월로 설정합니다.
            year -= 1;
            month = 12;
        }
        month = month < 10 ? '0' + month : month;
        var day = new Date(year, month, 0).getDate(); // 이전 월의 마지막 날
        day = day < 10 ? '0' + day : day;

        return year + "-" + month + "-" + day;
    };
    
    // 조회 기간 변경 시
    const onPeriodtypeChange = (value) => {
        setPeriodType(value);
    };

    // 수업 진행 여부 변경 시
    const onClassStatusChange = (e) => {
        let _classStatus = [...classStatus];

        if (e.checked)
            _classStatus.push(e.value);
        else
        _classStatus.splice(_classStatus.indexOf(e.value), 1);

        setClassStatus(_classStatus);
    };

    // 날짜 정렬 변경 시
    const onDatingChange = (value) => {
        setSortType(value);
    };

    // 검색
    function submitSearch(){
        activePage.current = 1;
        getClassSchedule();
    };

    // 검색어 입력 시
    const handleChange = (e) => {
        const { name, value } = e.target;
        
        setFormData({
            ...formData,
            
            [name]: value
        });
    };

    // 검색 기능
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            submitSearch();
          }
    };

    // 페이지 변경 시
    const onPageChange = (event) => {
        activePage.current = event.page + 1;
        getClassSchedule();
    };

     const dtContainerRef = useRef(null);
      useEffect(() => {
    const container = dtContainerRef.current;
    if (container) {
      const headers = container.querySelectorAll('.p-datatable-thead th');
      headers.forEach(header => {
        const text = header.textContent;
        header.setAttribute('aria-label', text);
      });
    }
  }, []);

    // '상태' 컬럼 body 템플릿 tag
    const statusTemplate = (rowData) => {
        const status = rowData.status.toLowerCase();
        const severity = getOrderSeverity(rowData);

        return <Tag value={status} severity={severity} aria-label={`수업 내역 상태: ${status}`}></Tag>;
    };

    const getOrderSeverity = (order) => {
        switch (order.status) {
            case '승인':
                return 'success';

            case '대기':
                return 'secondary';

            default:
                return null;
        }
    };

    // '교재' 컬럼 body 템플릿
    const bookTemplate = (rowData) => {
        return (
            <button type='button' onClick={() => bookconfirm(rowData)} className='bookBtn' aria-label={`${rowData.booktitle}교재 미리보기 버튼`}>{rowData.booktitle}</button>
        );
    };

    // 교재 클릭시 나오는 안내alert
    const bookconfirm = (data) => {
        confirmDialog({
             message: (
                <div className="flex flex-column align-items-center w-full gap-3  surface-border" role="alert">
                    <i className="pi pi-exclamation-circle text-5xl text-primary-500"></i>
                    <span className='mb-3'>수업에서 제공하는 강의 자료는 수강생만 학습목적으로 이용할 수 있습니다. 저작자의 허락없이 무단 복제 및 배포 시 민/형사상 책임을 질 수 있습니다. 동의하십니까?</span>
                </div>
            ),
            header: '주의사항',
            defaultFocus: 'accept',
            acceptLabel: '확인',
            rejectLabel: '취소' ,
            accept : () => bookAccept(data.fileurl, data.originfile),
            reject
        });
    };

    const [showPDFViewer, setShowPDFViewer] = useState(false); // PDF 뷰어 모달 상태 관리
    const [pdfUrl, setPdfUrl] = useState('');
    const [pdfName, setPdfName] = useState('');

    const bookAccept = (fileurl, originfile) => {
        //확인 버튼을 눌렀을 때 
        setPdfUrl(fileurl); // 테스트pdf
        setPdfName(originfile); // 테스트pdf
        setShowPDFViewer(true); // PDF 뷰어 모달 열기
    }

    const reject = () => {
        //취소 버튼을 눌렀을 때
    }

    const handleClosePDFViewer = () => {
        setShowPDFViewer(false); // PDF 뷰어 모달 닫기
    };

     // '수업 진행 여부' 컬럼 body 템플릿 
    const classStateTemplate = (rowData) => {
        let color, fontWeight;
        switch (rowData.classStatus) {
            case '진행':
                color = 'green';
                fontWeight = '600';
                break;
            case '미진행':
                color = 'gray';
                break;
            default:
                color = 'black';
        }

        return <span style={{ color: color, fontWeight: fontWeight }}>{rowData.classStatus}</span>;
    };
    
    // '학생 출석 여부' 컬럼 body 템플릿
    const attendanceTemplate = (rowData) => {
        let color, fontWeight;
        switch (rowData.attendance) {
            case '출석':
                color = 'green';
                fontWeight = '600';
                break;
            case '결석':
                color = 'gray';
                break;
            default:
                color = 'black';
        }

        return <span style={{ color: color, fontWeight: fontWeight }}>{rowData.attendance}</span>;
    };

    // 페이징 접근성 변경 템플릿
    const template1 = {
        layout: 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink',
        FirstPageLink: (options) => {
            return (
                <button
                    type="button"
                    ref={el => pageRefs.current[0] = el}
                    className={classNames(options.className, 'border-round')}
                    onClick={options.onClick}
                    disabled={options.disabled}
                    aria-label="처음으로"
                    tabIndex={options.disabled ? -1 : 0}
                >
                    <span className="p-3">
                        <i className="pi pi-angle-double-left"></i>
                    </span>
                </button>
            );
        },
        PrevPageLink: (options) => {
            return (
                <button
                    type="button"
                    ref={el => pageRefs.current[1] = el}
                    className={classNames(options.className, 'border-round')}
                    onClick={options.onClick}
                    disabled={options.disabled}
                    aria-label="이전"
                    tabIndex={options.disabled ? -1 : 0}
                >
                    <span className="p-3">
                        <i className="pi pi-angle-left"></i>
                    </span>
                </button>
            );
        },
        NextPageLink: (options) => {
            return (
                <button
                    type="button"
                    ref={el => pageRefs.current[pageRefs.current.length - 2] = el}
                    className={classNames(options.className, 'border-round')}
                    onClick={options.onClick}
                    disabled={options.disabled}
                    aria-label="다음"
                    tabIndex={options.disabled ? -1 : 0}
                >
                    <span className="p-3">
                        <i className="pi pi-angle-right"></i>
                    </span>
                </button>
            );
        },
        LastPageLink: (options) => {
            return (
                <button
                    type="button"
                    ref={el => pageRefs.current[pageRefs.current.length - 1] = el}
                    className={classNames(options.className, 'border-round')}
                    onClick={options.onClick}
                    disabled={options.disabled}
                    aria-label="끝으로"
                    tabIndex={options.disabled ? -1 : 0}
                >
                    <span className="p-3">
                        <i className="pi pi-angle-double-right"></i>
                    </span>
                </button>
            );
        },
        PageLinks: (options) => {
            const index = 2 + options.page;
            return (
                <button
                    type="button"
                   ref={el => {
                        pageRefs.current[index] = el;
                    }}
                    className={options.className}
                    onClick={options.onClick}
                    aria-label={options.page + 1}
                >
                    {options.page + 1}
                </button>
            );
        },
    };

    // 페이징 키보드 이벤트
    const pageRefs = useRef([]);
    pageRefs.current = Array(2 + Math.ceil(totalCnt / row)).fill(null);
    

    const handleKeyDown = (event) => {
        const visibleRefs = pageRefs.current.filter(ref => ref !== null);
        const currentFocusIndex = visibleRefs.findIndex(ref => ref === document.activeElement);
        
        if (currentFocusIndex === -1) return; // If no current element, do nothing

        if (event.key === 'ArrowDown') {
            event.preventDefault();
            let nextIndex = (currentFocusIndex + 1) % visibleRefs.length;
            visibleRefs[nextIndex].focus();
        } else if (event.key === 'ArrowUp') {
            event.preventDefault();
            let prevIndex = (currentFocusIndex - 1 + visibleRefs.length) % visibleRefs.length;
            visibleRefs[prevIndex].focus();
        }
    };


    const touchStartX = useRef(0);
    const touchEndX = useRef(0);
    const handleTouchStart = (event) => {
        touchStartX.current = event.touches[0].clientX;
    };

    const handleTouchEnd = (event) => {
        touchEndX.current = event.changedTouches[0].clientX;
        const swipeThreshold = 50; // Minimum swipe distance in pixels
        const visibleRefs = pageRefs.current.filter(ref => ref !== null);
        const currentFocusIndex = visibleRefs.findIndex(ref => ref === document.activeElement);

        if (currentFocusIndex === -1) return; // If no current element, do nothing

        if (touchStartX.current - touchEndX.current > swipeThreshold) {
            // Swipe left (Move focus to the next button)
            const nextIndex = (currentFocusIndex + 1) % visibleRefs.length;
            visibleRefs[nextIndex].focus();
        } else if (touchEndX.current - touchStartX.current > swipeThreshold) {
            // Swipe right (Move focus to the previous button)
            const prevIndex = (currentFocusIndex - 1 + visibleRefs.length) % visibleRefs.length;
            visibleRefs[prevIndex].focus();
        }
    };





    return (
         <div>
            <Layout title="수업 내역 확인" pagename="수업 내역 확인">
                <div className="contain"  tabIndex="-1">
                    <BreadCrumb model={items} home={home} role="navigation" aria-label="브레드크럼 내비게이션"  >
                        {items.map((item, index) => (
                            <span key={index} className={item.className} aria-current={index===items.length - 1 ? 'page' :
                                null}>
                                <Link to={item.url}>
                                {item.label}
                                </Link>
                            </span>
                        ))}
                    </BreadCrumb>

                    <div className='fullBox'>
                        <h3 className='visually-hidden'>수업 내역 확인</h3>
                        <div className='filterBox'>
                            <div>
                                <label htmlFor="ViewingPeriod" className=" block mb-2">조회 기간</label>
                                <select name="" id="ViewingPeriod" className="customSel" onChange={(e) => onPeriodtypeChange(e.target.value)} aria-label="조회 기간 선택">
                                    {periodtypelist.map((option, index) => (
                                        <option key={index} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                                {/* <Dropdown options={periodtypelist} label="조회 기간" onChange={onPeriodtypeChange} aria-label="조회 기간 선택"/>  */}
                            </div>

                           {periodType === 'etc' &&
                                <div className='flex gap-2'>
                                    <div>
                                        <label htmlFor="selfdate" className=" block mb-2">시작일 연도</label>
                                        <label htmlFor="startdate startYear" className='hidden'>연도</label>
                                         <select name="startYear" id="startYear" className="customSel"
                                            onChange={handleDropdownChange} onKeyDown={handleDropdownChange} aria-label="시작일 연도 선택" value={formData.startYear}>
                                            <option value="" disabled>연도 선택</option>
                                             {startdatelist.map(year => (
                                                <option key={year} value={year} >
                                                    {year}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    <div>
                                        <label htmlFor="selfdate" className=" block mb-2">시작일 월</label>

                                         <select name="startMonth" id="startMonth" className="customSel"
                                             onChange={handleDropdownChange} aria-label="시작일 월 선택"
                                             value={formData.startMonth}>
                                             <option value="" disabled>월 선택</option>
                                             {startdatemonthlist.map(month => (
                                             <option key={month} value={month} 
                                                 >
                                                 {month}월
                                             </option>
                                             ))}
                                         </select>
                                    </div>

                                    <div>
                                        <label htmlFor="selfdate" className=" block mb-2">시작일 일</label>

                                        <select name="startDay" id="startDay" className="customSel"
                                            onChange={handleDropdownChange} aria-label="시작일 일 선택"
                                            value={formData.startDay}>
                                            <option value="" disabled>일 선택</option>
                                            {startdatedaylist.map(day => (
                                                <option key={day} value={day} >
                                                {day}
                                            </option>
                                            ))}
                                        </select>
                                    </div>

                                     <div>
                                         <label htmlFor="selfdate" className=" block mb-2">종료일 연도</label>

                                         <select name="endYear" id="endYear" className="customSel"
                                             onChange={handleDropdownChange} aria-label="종료일 연도 선택"
                                             value={formData.endYear}>
                                             <option value="" disabled>연도 선택</option>
                                             {enddatelist.map(year => (
                                             <option key={year} value={year}
                                                 disabled={isEndYearDisabled(formData.startYear, formData.startMonth,
                                                 formData.startDay)}>
                                                 {year}
                                             </option>
                                             ))}
                                         </select>
                                     </div>
                                    
                                    <div>
                                        <label htmlFor="selfdate" className=" block mb-2">종료일 월</label>

                                        <select name="endMonth" id="endMonth" className="customSel"
                                            onChange={handleDropdownChange} aria-label="종료일 월 선택"
                                            value={formData.endMonth}>
                                            <option value="" disabled>월 선택</option>
                                            {enddatemonthlist.map(month => (
                                            <option key={month} value={month} disabled={isEndMonthDisabled(month,
                                                formData.startYear, formData.startMonth, formData.startDay,
                                                formData.endYear)}>
                                                {month}월
                                            </option>
                                            ))}
                                        </select>
                                    </div>

                                    <div>
                                        <label htmlFor="selfdate" className=" block mb-2">종료일 일</label>

                                        <select name="endDay" id="endDay" className="customSel"
                                            onChange={handleDropdownChange} aria-label="종료일 일 선택"
                                            value={formData.endDay}>
                                            <option value="" disabled>일 선택</option>
                                            {enddatedaylist.map(day => (
                                            <option key={day} value={day} disabled={isEndDayDisabled(day,
                                                formData.startYear, formData.startMonth, formData.startDay,
                                                formData.endYear, formData.endMonth)}>
                                                {day}
                                            </option>
                                            ))}
                                        </select>
                                    </div>

                                    {/* <Calendar value={selfDate} 
                                        onChange={onSelfDateChange}
                                        readOnlyInput 
                                        
                                        selectionMode="range"
                                        showIcon 
                                        id='selfdate'
                                        placeholder='날짜 선택' 
                                        aria-label="직접 설정한 날짜"/> */}

                                </div>
                            }
                            
                            <div>
                                <label htmlFor="dating" className="block mb-2">날짜 정렬</label>
                                <select name="" id="dating" className="customSel" onChange={(e) => onDatingChange(e.target.value)} aria-label="날짜 선택">
                                    {datinglist.map((option, index) => (
                                        <option key={index} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div>
                                <label htmlFor="search" className="block mb-2">검색</label>
                                <InputText id="search" 
                                    placeholder="검색어를 입력해주세요" 
                                    aria-label='검색어 입력' 
                                    name="searchtext" 
                                    value={formData.searchtext} 
                                    onChange={handleChange} 
                                    onKeyPress={handleKeyPress} 
                                    className="border"/>
                                <Button type="button" onClick={submitSearch} label="검색" className="searchbtn ml-2" aria-label={`${formData.searchtext}검색하기`}/>
                            </div>

                             <div className='w-100'>
                                <label htmlFor="classstate" className=" block mb-2">수업 진행 여부</label>
                                <div className="flex flex-wrap gap-3" style={{ height:'43px', margin:'0 10px 0 0' }}>
                                    <div className="flex align-items-center">
                                        <Checkbox inputId="ingredient1" name="ClassProgress" value="대기"
                                            onChange={onClassStatusChange} checked={classStatus.includes('대기')} />
                                        <label htmlFor="ingredient1" className="ml-2">대기</label>
                                    </div>
                                    <div className="flex align-items-center">
                                        <Checkbox inputId="ingredient2" name="ClassProgress" value="승인"
                                            onChange={onClassStatusChange} checked={classStatus.includes('승인')} />
                                        <label htmlFor="ingredient2" className="ml-2">승인</label>
                                    </div>
                                    <div className="flex align-items-center">
                                        <Checkbox inputId="ingredient3" name="ClassProgress" value="진행"
                                            onChange={onClassStatusChange} checked={classStatus.includes('진행')} />
                                        <label htmlFor="ingredient3" className="ml-2">진행</label>
                                    </div>
                                    <div className="flex align-items-center">
                                        <Checkbox inputId="ingredient4" name="ClassProgress" value="종료"
                                            onChange={onClassStatusChange} checked={classStatus.includes('종료')} />
                                        <label htmlFor="ingredient4" className="ml-2">종료</label>
                                    </div>
                                </div>
                                {/* <select name="" id="classstate" className="customSel" onChange={(e) => onClassStatusChange(e.target.value)} aria-label="수업 진행 여부 선택">
                                    {classstatuslist.map((option, index) => (
                                        <option key={index} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </select> */}
                            </div>
                        </div>

                        <div className="showtxt" role="region" aria-labelledby="showtxtHeading">
                            <h2 id="showtxtHeading" className="screen-reader-only">조회기간 및 정보</h2>
                            {periodType != 'etc' && (
                            (startdate && enddate) ? (
                            <p>조회기간 : {startdate} - {enddate}</p>
                            ) : (
                            <p>조회기간 : 모든 기간</p>
                            )
                            )}
                            {periodType == 'etc' && (
                            (startdate && enddate) && (
                            <p>조회기간 : {startdate} - {enddate}</p>
                            )
                            )}

                            <p>{ displayText1 }</p>
                            <p className="total pt-2">{displayText2}</p>
                        </div>
                        
                        <div className="tableBox">
                            
                            {/* pc */}
                            <div ref={dtContainerRef} >
                                <DataTable
                                    className="listpc"
                                    value={tableData}
                                    rows={5}
                                    tableStyle={{ minWidth: '50rem' }}
                                    emptyMessage="데이터가 없습니다" // No results found 메시지 커스터마이즈
                                  >
                                    <Column field="num" header="No"  bodyStyle={{ textAlign: 'center' }}></Column>
                                    <Column field="state" header="상태" body={statusTemplate}  bodyStyle={{ textAlign: 'center' }}></Column>
                                    <Column field="datefulltxt" header="날짜" bodyStyle={{ textAlign: 'center' }}></Column>
                                    <Column field="weektxt" header="요일" bodyStyle={{ textAlign: 'center' }}></Column>
                                    <Column field="starttxtStr" header="시작시간" bodyStyle={{ textAlign: 'center' }}></Column>
                                    <Column field="tuteename" header="이름" bodyStyle={{ textAlign: 'center' }}></Column>
                                    <Column field="booktitle" header="교재" body={bookTemplate} bodyStyle={{ textAlign: 'center' }}></Column>
                                    <Column field="classStatus" header="수업 진행 여부"  body={classStateTemplate} bodyStyle={{ textAlign: 'center' }}></Column>
                                    <Column field="attendance" header="학생 출석 여부" body={attendanceTemplate} bodyStyle={{ textAlign: 'center' }}></Column>
                                </DataTable>
                            </div>

                            {/* mob */}
                            <div className="listmob" >
                                <table className="table" role="table" >
                                     <thead className="screen-reader-only">
                                         <tr>
                                             <th scope="col">수업 내역 상태</th>
                                             <th scope="col">교재</th>
                                             <th scope="col">수업 날짜</th>
                                             <th scope="col">수업 시작 시간</th>
                                             <th scope="col">이름</th>
                                             <th scope="col">수업 진행 여부</th>
                                             <th scope="col">학생 출석 여부</th>
                                         </tr>
                                     </thead>
                                   <tbody>
                                       {tableData.map((rowData, index) => (
                                       <tr key={index}>
                                           <td>
                                               <Tag value={rowData.status.toLowerCase()}
                                                   severity={getOrderSeverity(rowData)} aria-label={`수업 내역 상태:
                                                   ${rowData.status.toLowerCase()}`}></Tag>
                                               {/*
                                               <b>{rowData.level}</b>
                                               */}
                                           </td>
                                           <td>
                                               <button type="button" onClick={bookconfirm}
                                                   className="bookBtn book mt-2 mb-2" aria-label={`${rowData.booktitle}
                                                   교재 미리보기 버튼 `}>
                                                   {rowData.booktitle}
                                               </button>
                                           </td>
                                           <td>
                                               {`${rowData.datefulltxt}(${rowData.weektxt}) `}

                                           </td>
                                           <td>
                                               {rowData.starttxtStr}
                                           </td>
                                           <td>
                                               {rowData.tuteename && ` ${rowData.tuteename}`}
                                           </td>
                                           <td>
                                               수업&nbsp;
                                               <span className={ rowData.status==='진행' ? 'attendance' :
                                                   rowData.status==='승인' ? 'text-red' : 'text-muted'
                                                   }>{rowData.status}</span>
                                           </td>
                                           <td>
                                               <div className={ rowData.attendance==='출석' ? 'attendance' : 'text-muted'
                                                   }>
                                                   {rowData.attendance}
                                               </div>
                                           </td>

                                       </tr>
                                       ))}
                                   </tbody>
                               </table>
                           </div>

                             <Paginator
                                role="navigation"
                                aria-label="페이지 탐색"
                                first={(activePage.current - 1) * row}
                                rows={row}
                                totalRecords={totalCnt}
                                onPageChange={onPageChange}
                                template={template1}
                                tabIndex="0"
                                onKeyDown={handleKeyDown}
                                onTouchStart={handleTouchStart}
                                onTouchEnd={handleTouchEnd}
                            />
                            
                        </div>
                    </div>
                </div>
            </Layout>

            {/* 교재 alert */}
            <ConfirmDialog draggable={false} style={{ width: '30vw' }} breakpoints={{ '1100px': '75vw', '960px': '80vw' }} className='bookDia' />
            
            {/* PDF 뷰어 모달 */}
            <PDFViewer showModal={showPDFViewer} onCloseModal={handleClosePDFViewer} pdfUrl={pdfUrl} pdfName={pdfName} />
        </div>
    );
}

export default ClassScheduleDetail;