/* /lms/learner/classResiList.jsx */
import React, { useState, useRef, useEffect } from "react";
import { useSelector } from 'react-redux';

import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ConfirmPopup } from 'primereact/confirmpopup';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Tooltip } from 'primereact/tooltip';
import { Paginator } from 'primereact/paginator';
import { classNames } from 'primereact/utils';
import { Toast } from 'primereact/toast';
import { Link } from 'react-router-dom';

import Layout from '../../../components/lms/layout/layout';
import PDFViewer from '../../../components/lms/modal/PDFViewer'; 

import axios from 'axios';

import config from '../../../config';
const apiUrl = config.apiUrl;

var dataList = [];

// 타이틀
export function useTitle(initialTitle) {
    const [title, setTitle] = useState(initialTitle);
    const updateTitle = () => {
        const htmlTitle = document.querySelector("title");
        htmlTitle.innerText = title;
    };

    useEffect(updateTitle, [title]);
    return setTitle;
};


const ClassResiList = () => {

    useTitle("수업 예약 내역");

    //브러드크럼
    const items = [ { label: '수업예약' }, { label: '수업 내역', className: 'nowpage' }, ];
    const home = { icon: 'pi pi-home', url: '/learner/dashboard' }

    const accessToken = useSelector(state => state.auth.accessToken);

    const [totalCnt, setTotalCnt] = useState(0);
    const activePage = useRef(1);
    const [row, setRowData] = useState(5);
    const [tableData, setTableData] = useState([...dataList]);

    const toast = useRef(null); //결과 메세지 창

    useEffect(() => {

        getMyClassSchedule();

    }, []);

    // 수업 예약 리스트 가져오기
    const getMyClassSchedule = async () => {

        var sdate = new Date();
        var startdateToString = getDateToString(sdate);

        try {
            const response = await axios.get(apiUrl+`/tutoring/MyClassSchedule?page=${activePage.current}&rows=${row}&sort=startdate,asc&start=${startdateToString}&end=2100-01-01`, {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            //console.log(res);
            var dataArr = [];
            if(res.success){
                var resData = response.data.data.schdules;
                //console.log(resData);
                if(resData){
                    var roundno = response.data.data.affiliation_availableno;
                    for(var i = 0; i < resData.length; i++){
                        var info = resData[i];
                        var starttxt = info.starttxt;
                        var starttxtArr = starttxt.split(":");
                        var startampm = "오전";
                        var starthour = Number(starttxtArr[0]);
                        if(starthour > 12){
                            starthour = starthour - 12;
                            startampm = "오후";
                        }
                        starthour = starthour.toString().padStart(2, '0');
                        info.starttxtStr = startampm + " " + starthour + ":" + starttxtArr[1];
                        
                        var endtxt = info.endtxt;
                        var endtxtArr = endtxt.split(":");
                        var endampm = "오전";
                        var endhour = Number(endtxtArr[0]);
                        if(endhour > 12){
                            endhour = endhour - 12;
                            endampm = "오후";
                        }
                        endhour = endhour.toString().padStart(2, '0');
                        info.endtxtStr = endampm + " " + endhour + ":" + endtxtArr[1];
                        info.roundtxt = info.round + "/" + roundno;

                        // 수업시작 24시간 전인지 확인
                        var now = new Date();
                        now.setDate(now.getDate() + 1);
                        var yesterday = new Date(now);
                        var yesterdayTime = yesterday.getTime();

                        var start = new Date(info.datefulltxt + " " + info.starttxt);
                        var startTime = start.getTime();

                        if(yesterdayTime < startTime){
                            info.isdeletable = true;
                        }else{
                            info.isdeletable = false;
                        }
                        
                        dataArr.push(info);
                    }
                }

                setTotalCnt(res.total);
            }else{
                setTotalCnt(0);
            }

            dataList = dataArr;

            setTableData(dataList);

        } catch (error) {
            console.error('Error:', error);
        }
    };

    // '취소' 컬럼 body 템플릿
    const deleteTemplate = (rowData) => {
        return (
            <>
                <ConfirmPopup />
                { rowData.isdeletable ?
                    <Button onClick={() => delbtn(rowData)} label="취소" severity="info" rounded  outlined aria-label={`수업 취소 버튼: ${rowData.roundtxt}`}/>
                    :
                    <Button label="취소" severity="info" rounded outlined disabled aria-label={`수업 취소 버튼: ${rowData.roundtxt}`}/>
                }
            </>
        );
    };

    // 개별 취소 클릭시
    const delbtn = (data) => {
        confirmDialog({
            message: (
                <div className="flex flex-column align-items-center w-full gap-3  surface-border" role="alert">
                    <i className="pi pi-exclamation-circle text-5xl text-primary-500"></i>
                    <span className='mb-3'>해당 일정은 {data.datetxt}({data.weektxt}) {data.starttxtStr} ~ {data.endtxtStr}입니다.<br/> 해당 수업 일정을 삭제 하시겠습니까?</span>
                </div>
            ),
            header: false,
            defaultFocus: 'accept',
            acceptLabel: '네',
            rejectLabel: '아니오' ,
            accept: () => delaccept(data.tutoringid, data.scheduleid),
            reject: delreject,
        });
    };

    // 개별 취소 - 확인
    const delaccept = async (tutoringid, scheduleid) => {
        try {
            const response = await axios.put(apiUrl+`/tutoring/schedule/cancel/${scheduleid}`, {}, {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            //console.log(res);
            if(res.success){
                toast.current.show({ severity: 'success',  detail: '취소가 완료되었습니다.', life: 2000 });//완료메세지
                getMyClassSchedule();
            }

        } catch (error) {
            console.error('Error:', error);
        }
    };

    // 개별 취소 - 취소
    const delreject = () => {};

    // '교재' 컬럼 body 템플릿
    const bookTemplate = (rowData) => {
        return (
            <button type='button' onClick={() => bookconfirm(rowData)} className='bookBtn' aria-label={`${rowData.booktitle}교재 미리보기 버튼`}>{rowData.booktitle}</button>
        );
    };

    // 교재 클릭시 나오는 안내alert
    const bookconfirm = (data) => {
        confirmDialog({
             message: (
                <div className="flex flex-column align-items-center w-full gap-3  surface-border" role="alert">
                    <i className="pi pi-exclamation-circle text-5xl text-primary-500"></i>
                    <span className='mb-3'>수업에서 제공하는 강의 자료는 수강생만 학습목적으로 이용할 수 있습니다. 저작자의 허락없이 무단 복제 및 배포 시 민/형사상 책임을 질 수 있습니다. 동의하십니까?</span>
                </div>
            ),
            header: '주의사항',
            defaultFocus: 'accept',
            acceptLabel: '확인',
            rejectLabel: '취소' ,
            accept : () => bookAccept(data.fileurl, data.originfile),
            reject
        });
    };

    const [showPDFViewer, setShowPDFViewer] = useState(false); // PDF 뷰어 모달 상태 관리
    const [pdfUrl, setPdfUrl] = useState('');
    const [pdfName, setPdfName] = useState('');

    // 확인 버튼을 눌렀을 때 
    const bookAccept = async (fileurl, originfile) => {

        //확인 버튼을 눌렀을 때 
        setPdfUrl(fileurl); // 테스트pdf
        setPdfName(originfile); // 테스트pdf
        setShowPDFViewer(true); // PDF 뷰어 모달 열기
    };

    const reject = () => {
        // 취소 버튼을 눌렀을 때
    };

    const handleClosePDFViewer = () => {
        setShowPDFViewer(false); // PDF 뷰어 모달 닫기
    };
   
    // 페이지 변경 시
    const onPageChange = (event) => {
        activePage.current = event.page + 1;
        getMyClassSchedule();
    };

    // DateToString
    const getDateToString = (targetDate) => {
        let year = targetDate.getFullYear();
        let month = targetDate.getMonth() + 1;
        let day = targetDate.getDate();
    
        // 한 자리 수인 경우 앞에 0을 붙여 두 자리로 만듭니다.
        month = month < 10 ? '0' + month : month;
        day = day < 10 ? '0' + day : day;
    
        const dateToString = year + '-' + month + '-' + day;
        return dateToString;
    };

    // 페이징 접근성 변경 템플릿
    const template1 = {
        layout: 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink',
        FirstPageLink: (options) => {
            return (
                <button
                    type="button"
                    ref={el => pageRefs.current[0] = el}
                    className={classNames(options.className, 'border-round')}
                    onClick={options.onClick}
                    disabled={options.disabled}
                    aria-label="처음으로"
                    tabIndex={options.disabled ? -1 : 0}
                >
                    <span className="p-3">
                        <i className="pi pi-angle-double-left"></i>
                    </span>
                </button>
            );
        },
        PrevPageLink: (options) => {
            return (
                <button
                    type="button"
                    ref={el => pageRefs.current[1] = el}
                    className={classNames(options.className, 'border-round')}
                    onClick={options.onClick}
                    disabled={options.disabled}
                    aria-label="이전"
                    tabIndex={options.disabled ? -1 : 0}
                >
                    <span className="p-3">
                        <i className="pi pi-angle-left"></i>
                    </span>
                </button>
            );
        },
        NextPageLink: (options) => {
            return (
                <button
                    type="button"
                    ref={el => pageRefs.current[pageRefs.current.length - 2] = el}
                    className={classNames(options.className, 'border-round')}
                    onClick={options.onClick}
                    disabled={options.disabled}
                    aria-label="다음"
                    tabIndex={options.disabled ? -1 : 0}
                >
                    <span className="p-3">
                        <i className="pi pi-angle-right"></i>
                    </span>
                </button>
            );
        },
        LastPageLink: (options) => {
            return (
                <button
                    type="button"
                    ref={el => pageRefs.current[pageRefs.current.length - 1] = el}
                    className={classNames(options.className, 'border-round')}
                    onClick={options.onClick}
                    disabled={options.disabled}
                    aria-label="끝으로"
                    tabIndex={options.disabled ? -1 : 0}
                >
                    <span className="p-3">
                        <i className="pi pi-angle-double-right"></i>
                    </span>
                </button>
            );
        },
        PageLinks: (options) => {
            const index = 2 + options.page;
            return (
                <button
                    type="button"
                   ref={el => {
                        pageRefs.current[index] = el;
                    }}
                    className={options.className}
                    onClick={options.onClick}
                    aria-label={options.page + 1}
                >
                    {options.page + 1}
                </button>
            );
        },
    };

    // 페이징 키보드 이벤트
    const pageRefs = useRef([]);
    pageRefs.current = Array(2 + Math.ceil(totalCnt / row)).fill(null);
    

    const handleKeyDown = (event) => {
        const visibleRefs = pageRefs.current.filter(ref => ref !== null);
        const currentFocusIndex = visibleRefs.findIndex(ref => ref === document.activeElement);
        
        if (currentFocusIndex === -1) return; // If no current element, do nothing

        if (event.key === 'ArrowDown') {
            event.preventDefault();
            let nextIndex = (currentFocusIndex + 1) % visibleRefs.length;
            visibleRefs[nextIndex].focus();
        } else if (event.key === 'ArrowUp') {
            event.preventDefault();
            let prevIndex = (currentFocusIndex - 1 + visibleRefs.length) % visibleRefs.length;
            visibleRefs[prevIndex].focus();
        }
    };


    const touchStartX = useRef(0);
    const touchEndX = useRef(0);
    const handleTouchStart = (event) => {
        touchStartX.current = event.touches[0].clientX;
    };

    const handleTouchEnd = (event) => {
        touchEndX.current = event.changedTouches[0].clientX;
        const swipeThreshold = 50; // Minimum swipe distance in pixels
        const visibleRefs = pageRefs.current.filter(ref => ref !== null);
        const currentFocusIndex = visibleRefs.findIndex(ref => ref === document.activeElement);

        if (currentFocusIndex === -1) return; // If no current element, do nothing

        if (touchStartX.current - touchEndX.current > swipeThreshold) {
            // Swipe left (Move focus to the next button)
            const nextIndex = (currentFocusIndex + 1) % visibleRefs.length;
            visibleRefs[nextIndex].focus();
        } else if (touchEndX.current - touchStartX.current > swipeThreshold) {
            // Swipe right (Move focus to the previous button)
            const prevIndex = (currentFocusIndex - 1 + visibleRefs.length) % visibleRefs.length;
            visibleRefs[prevIndex].focus();
        }
    };



    return (
        <div>
            <Layout title="수업 취소하기" pagename="수업 취소하기">

                <div className="contain learnermain">

                     <BreadCrumb model={items} home={home} role="navigation" aria-label="브레드크럼 내비게이션">
                        {items.map((item, index) => (
                        <span key={index} className={item.className} aria-current={index===items.length - 1 ? 'page' :
                            null}>
                            <Link to={item.url}>
                            {item.label}
                            </Link>
                        </span>
                        ))}
                    </BreadCrumb>


                        
                    <div className="selecttabele"  role="region" aria-label="수업 예약 리스트">
                        <div className="classReservation" style={{ width: '100%', padding:'20px' }}>
                            <h3>수업 예약 리스트</h3>
                            <div className="tableBox">
                                {/* pc */}
                                <DataTable
                                    className="listpc"
                                    value={tableData}
                                    rows={5}
                                    tableStyle={{ minWidth: '50rem' }}
                                    aria-label="수업 예약 목록 테이블"
                                    emptyMessage="데이터가 없습니다" // No results found 메시지 커스터마이즈
                                >
                                    <Column field="datefulltxt" header="강의날짜" bodyStyle={{ textAlign: 'center' }} aria-label="강의날짜"></Column>
                                    <Column field="weektxt" header="요일" bodyStyle={{ textAlign: 'center' }} aria-label="요일"></Column>
                                    <Column field="tutorname" header="강사" bodyStyle={{ textAlign: 'center' }} aria-label="강사"></Column>
                                    <Column field="starttxtStr" header="시작시간" bodyStyle={{ textAlign: 'center' }} aria-label="시작시간"></Column>
                                    <Column field="endtxtStr" header="종료시간" bodyStyle={{ textAlign: 'center' }} aria-label="종료시간"></Column>
                                    <Column field="booktitle" header="교재" body={bookTemplate} bodyStyle={{ textAlign: 'center' }} aria-label="교재"></Column>
                                    <Column
                                        field="delete"
                                        header={
                                            <div className="header-with-tooltip flex align-items-center gap-1">
                                                <span aria-label="수업 취소">수업취소</span>
                                                <i className="pi pi-question-circle" aria-hidden="true" aria-label="수업 시작 일정 24시간 전까지만 취소 가능합니다." aria-describedby="tooltip-delete" />
                                                <Tooltip id="tooltip-delete" target=".pi-question-circle" position="bottom" content="수업 시작 일정 24시간 전까지만 취소 가능합니다." />
                                            </div>
                                        }
                                        body={deleteTemplate}
                                        bodyStyle={{ textAlign: 'center' }}
                                        aria-label="수업취소"
                                    ></Column>
                                </DataTable>

                                {/* mob */}
                                <div className="listmob">
                                    {/* <ul className="table">
                                        {tableData.map((rowData, index) => (
                                        <li className="flex" key={index}>
                                            <div>
                                                <div className="top flex-column align-items-baseline">
                                                    <b className='order'>{rowData.roundtxt}</b>
                                                    <button type='button' onClick={bookconfirm} className='bookBtn book'
                                                        aria-label={`${rowData.booktitle} 교재 미리보기 버튼`}>
                                                        {rowData.booktitle}
                                                    </button>
                                                </div>
                                                <div className="bottom">
                                                    {rowData.datefulltxt}({rowData.weektxt}) {rowData.tutorname}
                                                </div>
                                            </div>
                                            <div className="flex flex-row justify-content-end btns">
                                                <Button label="취소" onClick={()=> delbtn(rowData)} severity="info"
                                                    rounded outlined aria-label="취소 버튼"/>
                                            </div>
                                        </li>
                                        ))}
                                    </ul> */}

                                    <table className="table" role="table">
                                        <thead className="screen-reader-only">
                                         <tr>
                                             <th scope="col">강의 날짜</th>
                                             <th scope="col">강의 시작 시간</th>
                                             <th scope="col">강의 종료시간</th>
                                             <th scope="col">강사</th>
                                             <th scope="col">교재</th>
                                             <th scope="col">수업 삭제</th>
                                         </tr>
                                        </thead>
                                        <tbody>
                                        {tableData.map((rowData, index) => (
                                            <tr key={index}>
                                                 <td>
                                                    {rowData.datefulltxt}({rowData.weektxt}) 
                                                </td>
                                                <td className="inline-block">
                                                    {rowData.starttxtStr} ~
                                                </td>
                                                <td className="inline-block">
                                                    {rowData.endtxtStr}
                                                </td>
                                                <td >
                                                    {rowData.tutorname}
                                                </td>
                                                <td className="mt-2">
                                                    <button type='button' onClick={bookconfirm} className='bookBtn book'
                                                    aria-label={`${rowData.booktitle} 교재 미리보기 버튼`}>
                                                    {rowData.booktitle}
                                                    </button>
                                                </td>
                                                <td className="flex flex-row justify-content-end btns">
                                                     <Button label="취소" onClick={()=> delbtn(rowData)} severity="info"
                                                    rounded outlined aria-label="취소 버튼"/>
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>

                                <Paginator
                                    role="navigation"
                                    aria-label="페이지 탐색"
                                    first={(activePage.current - 1) * row}
                                    rows={row}
                                    totalRecords={totalCnt}
                                    onPageChange={onPageChange}
                                    template={template1}
                                    tabIndex="0"
                                    onKeyDown={handleKeyDown}
                                    onTouchStart={handleTouchStart}
                                    onTouchEnd={handleTouchEnd}
                                />
                            

                                
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>

            <Toast ref={toast} role="alert"/>

            {/* 교재 alert */}
            <ConfirmDialog draggable={false} style={{ width: '30vw' }} breakpoints={{ '1100px': '75vw', '960px': '80vw' }}
                className='bookDia' />
            
            {/* PDF 뷰어 모달 */}
            <PDFViewer showModal={showPDFViewer} onCloseModal={handleClosePDFViewer} pdfUrl={pdfUrl} pdfName={pdfName} />
        </div>
    );
};
export default ClassResiList;