import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { ConfirmPopup } from 'primereact/confirmpopup';
import { Toast } from 'primereact/toast';
import { Paginator } from 'primereact/paginator';
import { classNames } from 'primereact/utils';
import { Link } from 'react-router-dom';

//import Dropdown from '../../../../components/dropdown'; // 커스텀 접근성 드롭다운 
//import Dropdown from '../../../../components/dropdownValue'; // 커스텀 접근성 드롭다운 (value값 포함)
import Layout from '../../../../components/lms/layout/layout';

import axios from 'axios';

import config from '../../../../config';
const apiUrl = config.apiUrl;

var dataList = [];

// 타이틀
export function useTitle(initialTitle) {
    const [title, setTitle] = useState(initialTitle);
    const updateTitle = () => {
        const htmlTitle = document.querySelector("title");
        htmlTitle.innerText = title;
    };

    useEffect(updateTitle, [title]);
    return setTitle;
};


const ClassScheduleChk = () => {

    useTitle("수업 일정 확인");

    const accessToken = useSelector(state => state.auth.accessToken);

    const [totalCnt, setTotalCnt] = useState(0);
    const activePage = useRef(1);
    const [row, setRowData] = useState(5);
    const [tableData, setTableData] = useState([...dataList]);
    const [formData, setFormData] = useState({
        searchtext: ''
    });

    // 날짜 정렬
    const datinglist = [
        { label: '오름차순', value: 'asc' },
        { label: '내림차순', value: 'desc' }
    ];
    const [sortType, setSortType] = useState('asc');

    const toast = useRef(null); //결과 메세지 창

    //브러드크럼
    const items = [{ label: '수업관리' }, { label: '수업 일정 확인', className: 'nowpage' }, ];
    const home = { icon: 'pi pi-home', url: '/teacher/dashboard' }

    const displayText = `총 ${totalCnt}건 ${activePage.current}페이지 ${sortType === 'desc' ? '내림차순' : '오름차순'}으로 표시중`;


    // 정렬 기준 변경 시마다 일정 정보 가져오기
    useEffect(() => {

        getClassSchedule();

    }, [sortType]);

    // 수업 리스트 가져오기
    const getClassSchedule = async () => {

        var sortStr = "";
        if(sortType !== ""){
            var sorting = "desc";
            if(sortType == "asc"){
                sorting = "asc";
            }
            sortStr = "startdate" + "," + sorting;
        }
        
        var sdate = new Date();
        var startdateToString = getDateToString(sdate);
        
        try {
            const response = await axios.get(apiUrl+`/tutoring/MyClassSchedule?page=${activePage.current}&rows=${row}&sort=${sortStr}&searchtext=${formData.searchtext}&start=${startdateToString}&end=2100-01-01`, {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            //console.log(res);
            var dataArr = [];
            if(res.success){
                var resData = response.data.data.schdules;
                //console.log(resData);
                if(resData){
                    var roundno = response.data.data.affiliation_availableno;
                    for(var i = 0; i < resData.length; i++){
                        var info = resData[i];
                        var starttxt = info.starttxt;
                        var starttxtArr = starttxt.split(":");
                        var startampm = "오전";
                        var starthour = Number(starttxtArr[0]);
                        if(starthour > 12){
                            starthour = starthour - 12;
                            startampm = "오후";
                        }
                        starthour = starthour.toString().padStart(2, '0');
                        info.starttxtStr = startampm + " " + starthour + ":" + starttxtArr[1];
                        
                        var endtxt = info.endtxt;
                        var endtxtArr = endtxt.split(":");
                        var endampm = "오전";
                        var endhour = Number(endtxtArr[0]);
                        if(endhour > 12){
                            endhour = endhour - 12;
                            endampm = "오후";
                        }
                        endhour = endhour.toString().padStart(2, '0');
                        info.endtxtStr = endampm + " " + endhour + ":" + endtxtArr[1];
                        info.roundtxt = info.round + "/" + roundno;

                        info.title = info.starttxtStr;
                        info.id = info.scheduleid;
                        info.start = info.datefulltxt;
                        
                        dataArr.push(info);
                    }
                }

                setTotalCnt(res.total);
            }else{
                setTotalCnt(0);
            }

            dataList = dataArr;

            setTableData(dataList);

        } catch (error) {
            console.error('Error:', error);
        }
    };

    // 날짜 정렬 기준 변경 시
    const onDatingChange = (value) => {
        setSortType(value);
    };

    // 검색
    function submitSearch(){
        activePage.current = 1;
        getClassSchedule();
    };

    // 검색어 입력 시
    const handleChange = (e) => {
        const { name, value } = e.target;
        
        setFormData({
            ...formData,
            [name]: value
        });
    };

    // 검색 기능
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            submitSearch();
          }
    };

    // 페이지 변경 시
    const onPageChange = (event) => {
        activePage.current = event.page + 1;
        getClassSchedule();
    };

    // '삭제' 컬럼 body 템플릿
    const deleteTemplate = (rowData) => {
        return (
            <>
                <ConfirmPopup />
                { rowData.tuteename ?
                    <Button label="삭제" severity="info" rounded outlined disabled aria-label="삭제 버튼"/>
                    :
                    <Button label="삭제" severity="info" rounded outlined onClick={() => delbtn(rowData)} aria-label="삭제 버튼"/>
                }
            </>
        );
    };
       
    // 개별 삭제 클릭시
    const delbtn = (data) => {
        confirmDialog({
            message: (
                <div role="alert">
                    해당 일정은 {data.datetxt}({data.weektxt}) {data.starttxtStr} ~ {data.endtxtStr}입니다.<br/> 해당 수업 일정을 삭제 하시겠습니까?
                </div>
            ),
            header: false,
            defaultFocus: 'accept',
            acceptLabel: '예',
            rejectLabel: '아니오',
            accept: () => delaccept(data.tutoringid, data.scheduleid),
            reject: delreject,
        });
    };

    // 개별 삭제 - 확인
    const delaccept = async (tutoringid, scheduleid) => {
        try {
            const response = await axios.delete(apiUrl+`/tutoring/deleteSchedule?scheduleid=${scheduleid}&tutoringid=${tutoringid}`, {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            //console.log(res);
            if(res.success){
                toast.current.show({ severity: 'success',  detail: '삭제가 완료되었습니다.', life: 2000 });//완료메세지
                activePage.current = 1;
                getClassSchedule();
            }

        } catch (error) {
            console.error('Error:', error);
        }
    };

    // 개별 삭제 - 취소
    const delreject = () => {};

    // DateToString
    const getDateToString = (targetDate) => {
        let year = targetDate.getFullYear();
        let month = targetDate.getMonth() + 1;
        let day = targetDate.getDate();
    
        // 한 자리 수인 경우 앞에 0을 붙여 두 자리로 만듭니다.
        month = month < 10 ? '0' + month : month;
        day = day < 10 ? '0' + day : day;
    
        const dateToString = year + '-' + month + '-' + day;
        return dateToString;
    };

    // 페이징 접근성 변경 템플릿
    const template1 = {
        layout: 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink',
        FirstPageLink: (options) => {
            return (
                <button
                    type="button"
                    ref={el => pageRefs.current[0] = el}
                    className={classNames(options.className, 'border-round')}
                    onClick={options.onClick}
                    disabled={options.disabled}
                    aria-label="처음으로"
                    tabIndex={options.disabled ? -1 : 0}
                >
                    <span className="p-3">
                        <i className="pi pi-angle-double-left"></i>
                    </span>
                </button>
            );
        },
        PrevPageLink: (options) => {
            return (
                <button
                    type="button"
                    ref={el => pageRefs.current[1] = el}
                    className={classNames(options.className, 'border-round')}
                    onClick={options.onClick}
                    disabled={options.disabled}
                    aria-label="이전"
                    tabIndex={options.disabled ? -1 : 0}
                >
                    <span className="p-3">
                        <i className="pi pi-angle-left"></i>
                    </span>
                </button>
            );
        },
        NextPageLink: (options) => {
            return (
                <button
                    type="button"
                    ref={el => pageRefs.current[pageRefs.current.length - 2] = el}
                    className={classNames(options.className, 'border-round')}
                    onClick={options.onClick}
                    disabled={options.disabled}
                    aria-label="다음"
                    tabIndex={options.disabled ? -1 : 0}
                >
                    <span className="p-3">
                        <i className="pi pi-angle-right"></i>
                    </span>
                </button>
            );
        },
        LastPageLink: (options) => {
            return (
                <button
                    type="button"
                    ref={el => pageRefs.current[pageRefs.current.length - 1] = el}
                    className={classNames(options.className, 'border-round')}
                    onClick={options.onClick}
                    disabled={options.disabled}
                    aria-label="끝으로"
                    tabIndex={options.disabled ? -1 : 0}
                >
                    <span className="p-3">
                        <i className="pi pi-angle-double-right"></i>
                    </span>
                </button>
            );
        },
        PageLinks: (options) => {
            const index = 2 + options.page;
            return (
                <button
                    type="button"
                   ref={el => {
                        pageRefs.current[index] = el;
                    }}
                    className={options.className}
                    onClick={options.onClick}
                    aria-label={options.page + 1}
                >
                    {options.page + 1}
                </button>
            );
        },
    };

    // 페이징 키보드 이벤트
    const pageRefs = useRef([]);
    pageRefs.current = Array(2 + Math.ceil(totalCnt / row)).fill(null);
    

    const handleKeyDown = (event) => {
        const visibleRefs = pageRefs.current.filter(ref => ref !== null);
        const currentFocusIndex = visibleRefs.findIndex(ref => ref === document.activeElement);
        
        if (currentFocusIndex === -1) return; // If no current element, do nothing

        if (event.key === 'ArrowDown') {
            event.preventDefault();
            let nextIndex = (currentFocusIndex + 1) % visibleRefs.length;
            visibleRefs[nextIndex].focus();
        } else if (event.key === 'ArrowUp') {
            event.preventDefault();
            let prevIndex = (currentFocusIndex - 1 + visibleRefs.length) % visibleRefs.length;
            visibleRefs[prevIndex].focus();
        }
    };


    const touchStartX = useRef(0);
    const touchEndX = useRef(0);
    const handleTouchStart = (event) => {
        touchStartX.current = event.touches[0].clientX;
    };

    const handleTouchEnd = (event) => {
        touchEndX.current = event.changedTouches[0].clientX;
        const swipeThreshold = 50; // Minimum swipe distance in pixels
        const visibleRefs = pageRefs.current.filter(ref => ref !== null);
        const currentFocusIndex = visibleRefs.findIndex(ref => ref === document.activeElement);

        if (currentFocusIndex === -1) return; // If no current element, do nothing

        if (touchStartX.current - touchEndX.current > swipeThreshold) {
            // Swipe left (Move focus to the next button)
            const nextIndex = (currentFocusIndex + 1) % visibleRefs.length;
            visibleRefs[nextIndex].focus();
        } else if (touchEndX.current - touchStartX.current > swipeThreshold) {
            // Swipe right (Move focus to the previous button)
            const prevIndex = (currentFocusIndex - 1 + visibleRefs.length) % visibleRefs.length;
            visibleRefs[prevIndex].focus();
        }
    };



    return (
         <div>
            <Layout title="수업 일정 확인" pagename="수업 일정 확인">
                <div className="contain" tabIndex="-1">
                    <BreadCrumb model={items} home={home} role="navigation" aria-label="브레드크럼 내비게이션">
                        {items.map((item, index) => (
                            <span key={index} className={item.className} aria-current={index===items.length - 1 ? 'page' :
                                null}>
                                <Link to={item.url}>
                                {item.label}
                                </Link>
                            </span>
                        ))}
                    </BreadCrumb>

                    <div className='fullBox'>
                        <h3 className='visually-hidden'>수업 일정 확인</h3>
                        <div className='filterBox'>
                            <div>
                                <label htmlFor="dating" className=" block mb-2">날짜 정렬</label>
                                <select name="" id="dating" className="customSel" onChange={(e) => onDatingChange(e.target.value)} aria-label="날짜 선택">
                                    {datinglist.map((option, index) => (
                                        <option key={index} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                                {/* <Dropdown id="dating" options={datinglist} label="날짜 정렬" onChange={onDatingChange}/>  */}
                            </div>

                            <div>
                                <label htmlFor="search" className="block mb-2">검색</label>
                                <InputText id="search" 
                                    placeholder="검색어를 입력해주세요" 
                                    aria-label='검색어 입력' 
                                    name="searchtext" 
                                    value={formData.searchtext} 
                                    onChange={handleChange} 
                                    onKeyPress={handleKeyPress} 
                                    className="border"/>
                                <Button type="button" onClick={submitSearch} label="검색" className="searchbtn ml-2" aria-label={`${formData.searchtext}검색하기`}/>
                            </div>
                        </div>

                        <div className="showtxt">
                            <p className="total">{displayText}</p>
                        </div> 
                    
                        <div className="tableBox">
                            

                            {/* pc */}
                            <DataTable
                                className="listpc"
                                value={tableData}
                                rows={5}
                                tableStyle={{ minWidth: '50rem' }}
                                emptyMessage="데이터가 없습니다" // No results found 메시지 커스터마이즈
                            >
                                <Column field="datefulltxt" header="날짜" bodyStyle={{ textAlign: 'center' }}></Column>
                                <Column field="weektxt" header="요일" bodyStyle={{ textAlign: 'center' }}></Column>
                                <Column field="starttxtStr" header="시작시간" bodyStyle={{ textAlign: 'center' }}></Column>
                                <Column field="endtxtStr" header="종료시간" bodyStyle={{ textAlign: 'center' }}></Column>
                                <Column field="delete" className='deltebtn' header="삭제" body={deleteTemplate} bodyStyle={{ textAlign: 'center' }}></Column>
                            </DataTable>

                            {/* mob */}
                            <div className="listmob">
                                <table className="table" role="table">
                                    <thead className="screen-reader-only">
                                         <tr>
                                             <th scope="col">수업 날짜</th>
                                             <th scope="col">수업 시작 시간</th>
                                             <th scope="col">수업 종료시간</th>
                                             <th scope="col">수업 삭제</th>
                                         </tr>
                                     </thead>
                                    <tbody>
                                        {tableData.map((rowData, index) => (
                                        <tr key={index}>
                                            <td>
                                                <b>{`${rowData.datefulltxt}(${rowData.weektxt})`}</b>
                                            </td>
                                            <td className='inline-block'>
                                                {`${rowData.starttxtStr}`}&nbsp;
                                            </td>
                                            <td className='inline-block'>
                                                &nbsp;{`${rowData.endtxtStr}`}
                                            </td>
                                            <td className="flex align-items-center btns">
                                                {rowData.tuteename ? (
                                                <Button label="삭제" severity="info" rounded outlined disabled
                                                    aria-label="삭제하기 버튼" />
                                                ) : (
                                                <Button label="삭제" severity="info" rounded outlined onClick={()=>
                                                    delbtn(rowData)}
                                                    aria-label="삭제하기 버튼"
                                                    />
                                                    )}
                                            </td>
                                        </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                            <Paginator
                                role="navigation"
                                aria-label="페이지 탐색"
                                first={(activePage.current - 1) * row}
                                rows={row}
                                totalRecords={totalCnt}
                                onPageChange={onPageChange}
                                template={template1}
                                tabIndex="0"
                                onKeyDown={handleKeyDown}
                                onTouchStart={handleTouchStart}
                                onTouchEnd={handleTouchEnd}
                            />

                            
                        </div>
                    </div>
                </div>
            </Layout>
                                    
            <ConfirmDialog draggable={false}  breakpoints={{ '1100px': '75vw', '960px': '60vw', '430px': '80vw' }}
                className='bookDia' />
            <Toast ref={toast} role="alert"/>
        </div>
    );
}

export default ClassScheduleChk;