
import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import flogo from '../../assets/img/footerlogo.png'; // 푸터로고 이미지
import { Accordion, AccordionTab } from 'primereact/accordion';
import axios from 'axios';

import config from '../../config';
const apiUrl = config.apiUrl;

function Main() {

    const [siteData, setSiteData] = useState({
        address: "",
        bisunessnumber: "",
        ceo: "",
        company: "",
        detailaddress: "",
        email: "",
        favicon: "",
        fax: "",
        logo: "",
        mailordersales: "",
        office_address: "",
        office_detailaddress: "",
        recipientemail: "",
        securitymanager: "",
        senderemail: "",
        sitename: "",
        siteurl: "",
        tel: "",
    });

    useEffect(() => {

        // 사이트 정보 가져오기
        const getSiteInfo = async () => {
            try {
                const response = await axios.get(apiUrl+'/site');
                //console.log(response.data.data);
                setSiteData(response.data.data);
            
            } catch (error) {
               console.error('Error:', error);
            }
        }; 

        getSiteInfo();
        
    }, [])

    return (
        <footer>
            <div className="container">

                {/* pc */}
                <div className='pc'>
                    <div className="f_left">
                        <div className="f_logo"><img src={flogo} alt="하티웍스로고" /></div>
                        <div className="addr">
                            <ul>
                                <li>상호명 : { siteData.company } | 대표자명 : { siteData.ceo }</li>
                                <li>주소 (법인) : { siteData.address } { siteData.detailaddress }</li>
                                <li>주소 (사무국) : { siteData.office_address } { siteData.office_detailaddress }</li>
                                <li>사업자등록번호 : { siteData.bisunessnumber } | 통신판매업신고번호 : { siteData.mailordersales }</li>
                            </ul>
                        </div>
                    </div>
                    <div className="f_right">
                        <div>
                            <p className="ftitle">COMPANY</p>
                            <p className="txt">
                                <Link to="https://www.heartyworks.com/" target="_blank">회사소개</Link>
                            </p>
                            <p className="txt">
                                <Link to="https://blog.naver.com/koreanatyourdoor" target="_blank">블로그</Link>
                            </p>
                            <p className="txt">
                                <Link to="https://www.notion.so/koreanatyourdoor/9777129ba4724581a8bb5fba62381419" target="_blank">언론홍보
                                </Link>
                            </p>
                        </div>
                        <div>
                            <p className="ftitle">POLICIES</p>
                            <p className="txt">이용약관</p>
                            <p className="txt">개인정보처리방침</p>
                        </div>
                        <div>
                            <p className="ftitle">FAQ</p>
                            <p className="txt">{ siteData.tel }</p>
                            <p >주중 9시~18시 <br /> (점심시간 12:30~13:30 / 주말 및 공휴일 제외)</p>
                            <p >{ siteData.email }</p>
                        </div>
                    </div>
                </div>
                
                {/* mob */}
                <div className='mob'>
                    <div className="f_logo"><img src={flogo} alt="하티웍스로고" /></div>
                    <Accordion multiple activeIndex={[0]}>
                        <AccordionTab header="COMPANY">
                            <ul>
                                <li>
                                <Link to="https://www.heartyworks.com/" target="_blank">회사소개</Link>
                                </li>
                                <li>
                                <Link to="https://blog.naver.com/koreanatyourdoor" target="_blank">블로그</Link>
                                </li>
                                <li>
                                <Link to="https://www.notion.so/koreanatyourdoor/9777129ba4724581a8bb5fba62381419" target="_blank">언론홍보
                                </Link>
                                </li>
                            </ul>
                        </AccordionTab>
                        <AccordionTab header="POLICIES">
                            <ul>
                                <li>
                                <Link>이용약관</Link>
                                </li>
                                <li>
                                <Link>개인정보처리방침</Link>
                                </li>
                            </ul>
                        </AccordionTab>
                        <AccordionTab header="FAQ">
                            <ul>
                                <li>{ siteData.tel }</li>
                                <li>주중 9시~18시 <br /> (점심시간 12:30~13:30 / 주말 및 공휴일 제외)</li>
                                <li>{ siteData.email }</li>
                            </ul>
                        </AccordionTab>
                    </Accordion>
                    <div className="addr">
                        <ul>
                            <li>상호명 : { siteData.company } | 대표자명 : { siteData.ceo }</li>
                            <li>{ siteData.address }</li>
                            <li>{ siteData.detailaddress }</li>
                            <li>사업자등록번호 : { siteData.bisunessnumber } | 통신판매업신고번호 : { siteData.mailordersales }</li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Main;